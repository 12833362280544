import type { MenuItem } from 'primevue/menuitem';

export type RouteTabMenuItem = MenuItem & {
  to?: string;
  exact?: boolean;
};

const currentTabIndexByRoute = (tabItems: RouteTabMenuItem[], exact: boolean) => {
  const route = useRoute();

  // sort tabItems.to by length in descending order to match the longest path first
  const tabItemsCopy = JSON.parse(JSON.stringify(tabItems));
  // add origin index to keep track of the original index
  tabItemsCopy.forEach((item, index) => {
    item.originIndex = index;
  });
  tabItemsCopy.sort((a: RouteTabMenuItem, b: RouteTabMenuItem) => {
    return (b.to?.length || 0) - (a.to?.length || 0);
  });

  const index = tabItemsCopy.findIndex((item) => {
    if (!item.to) {
      return false;
    }

    if (item.exact !== undefined || exact) {
      return route.path === item.to;
    }

    return route.path.startsWith(item.to);
  });

  return index === -1 ? 0 : tabItemsCopy[index].originIndex;
};

export const useRouteTabMenu = (props: { tabItems: RouteTabMenuItem[]; exact?: boolean; onTabChange?: (event: any) => void }) => {
  const { tabItems, exact } = props;
  const exactValue = exact === undefined ? true : exact;

  const currentTabIndex = ref(currentTabIndexByRoute(tabItems, exactValue));

  const onTabChange = (event: any) => {
    if (props.onTabChange) {
      props.onTabChange(event);
    }
    currentTabIndex.value = event.index;

    if (props.tabItems[event.index].to) {
      return;
    }

    // add tabIndex query param to url
    navigateTo({
      query: {
        tabIndex: event.index,
      },
    });
  };

  const refreshTabIndex = () => {
    currentTabIndex.value = currentTabIndexByRoute(tabItems, exactValue);
  };

  return {
    currentTabIndex,
    onTabChange,
    refreshTabIndex,
  };
};
